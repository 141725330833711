import React, { Fragment } from "react";
import Helmet from "react-helmet";
import PageHeader from "../components/PageHeader";
import Layout from "../components/Layout";
import OurWork from "../components/OurWork";
import Gallery from "../components/Gallery";

export const OurWorkTemplate = ({ title, carousel }) => (
  <Fragment>
    <PageHeader title={"Our Work"} paintColor={"purple"} />
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-10 is-offset-1">
          <section className="section is-small">
            <div className="has-text-centered">
              {/* <h3 className="is-size-2 title-underline">

                </h3> */}
              <p className="is-size-5">
                For years, Unveil has been building unparalleled exhibition
                booths and creating unique experiences for brands to showcase
                their products and services. From custom exhibit booths at trade
                shows and curated furniture rentals at your company’s corporate
                events to executive seating arrangements at global conferences
                and elegant display graphics at private events, Unveil is
                prepared to work with you to turn your goals into reality.
              </p>
            </div>
          </section>
        </div>
        <div className="column is-12">
          <OurWork />
          {carousel ? (
            <section className="section">
              <div className="has-text-centered">
                <h3 className="is-size-2 title-underline">Related Projects</h3>
              </div>
              <Gallery slides={carousel} />
            </section>
          ) : null}
        </div>
      </div>
    </div>
  </Fragment>
);

const OurWorkPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <OurWorkTemplate
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        carousel={post.frontmatter.carousel}
      />
    </Layout>
  );
};

export default OurWorkPage;

export const pageQuery = graphql`
  query OurWorkByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        carousel {
          heading
          subHeading
          linkText
          linksTo
          galleryImgs {
            image {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
